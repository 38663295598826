import './App.css';
import Questions from './questions.json'
import React,{useState} from 'react'
import Axios from 'axios'

var check = 0

function App() {
    const[data, setData] = useState({})
    function submit(e){
        e.preventDefault();
        const userData = {
          dateOfBirth: data.dateOfBirth,
          grade: data.grade,
          school: data.school,
          gender: data.gender,
          zipCode: data.zipCode,
          borough: data.borough,
          ethnicity: data.ethnicity,
          language: data.language,
          //      General
          favArtist: data.favArtist,
          favSocialMedia: data.favSocialMedia,
          favFood: data.favFood,
          favVideoGame: data.favVideoGame,
          instrument: data.instrument,
          //      Education
          likeSchool: data.likeSchool,
          favSubject: data.favSubject,
          graduate: data.graduate,
          schoolMatters: data.schoolMatters,
          goToCollage: data.goToCollage,
          repeatGrade: data.repeatGrade,
          //      Faith & Religion
          god: data.god,
          churchOrMosque: data.churchOrMosque,
          faithImportant: data.faithImportant,
          denomination: data.denomination,
          community: data.community,
          //      Hope for the Future
          hopeful: data.hopeful,
          betterOrWorse: data.betterOrWorse,
          concernsForFuture: data.concernsForFuture,
          positiveDifference: data.positiveDifference,
          //    Social Issues
          mostImportantIssue: data.mostImportantIssue,
          cityDoingEnough: data.cityDoingEnough,
          //      Mental Health
          concernedForMentalHealth: data.concernedForMentalHealth,
          engagedHarmfulOrRisky: data.engagedHarmfulOrRisky,
          thoughtOfSuicide: data.thoughtOfSuicide,
          supportive: data.supportive,
          mentor: data.mentor,
        }
        var answers = Object.values(userData)
        check = 0
        answers.forEach((item) => {
          if(item === undefined || item.length === 0){
            console.log( item,"bad")
          }else{
            check++
          }
        })
        console.log(check)

        if(check === answers.length){
          Axios.post("https://student-survey-production.onrender.com/api/post",userData)
          .then((response) => {
            console.log(response.status, response.data);
          })
          document.getElementById("survey").style.display = 'none';
          document.getElementById("end").style.display='block';

        }else{
          alert("make sure all questions are answered before submiting")
        }

    }

    function handle(e){
        const newdata = {...data}
        if(e.target.type === "checkbox"){
          if(e.target.name in data){
            if(e.target.checked === false){

              const index = data[e.target.name].indexOf(e.target.value)
              if (index > -1) { 
                data[e.target.name].splice(index, 1);
              }

            }else{
              data[e.target.name].push(e.target.value)
            }
          }else{
            newdata[e.target.name] = [e.target.value]
          }
        }else{
          if(e.target.value === "Yes" || e.target.value === "Better"){
            newdata[e.target.name] = true
          }else if(e.target.value === "No" || e.target.value === "Worse"){
            newdata[e.target.name] = false
          }else{
          newdata[e.target.name] = e.target.value
          }
        }
        setData(newdata)
        console.log(newdata)
    }

    function next(){
      document.getElementById('start').style.display = 'none'
      document.getElementById('survey').style.display = 'block'
    }


  return(
    <div>
      <div align='center'>
        <img width='350px' alt='Code For Life Logo' src={ require('./Logo_CodeForLife.png') }/>
        <h1 id='heading'>NYC Student Survey</h1>
        <hr/>
      </div>
      <div id="start">
        <p>Welcome to the NYC Student Survey! This survey was designed and coded by Bronx and Harlem teenagers ages 14-17 who are a part of Code for Life program (<a href='https://codeforlife.us/'>https://codeforlife.us</a>). With the guidance of tech industry leaders, these students developed a safe and secure survey for students ages 11-18 to participate from throughout the five boroughs. Everyone who takes the survey will do so anonymously. Our goal is to create a platform for NYC students to be able to make their voices heard. This is your opportunity to make your voice heard. Code for Life will share the results of the survey widely before the end of 2023 through an online app, reports, and forums. Your input will help inform educators. clergy, policy makers, and youth leaders.</p>
        <div id='startButton'>
          <button onClick={() => next()}>Start Survey</button>
        </div>
      </div>
      <form onSubmit={(e) => submit(e)} id="survey">
        {
          Questions.questions.map( (question, index) =>{
            if(question.type === 'checkbox' || question.type === 'radio'){
              return(
                <div key={index}>
                <h1>{question.content}</h1>
                <div>
                
                {
                  question.options.map((option,i) => {
                    return(
                      
                      <div >
                        <input className='optionQuestion' onChange={(e)=>handle(e)} id={option} value={option} name={question.name} type={question.type} />
                        <label className='optionQuestion' >{option}</label><br></br>
                      </div>
                      
                    )
                  })
                }
                
                </div>
              </div>
              )
            }else if(question.type === 'date' || question.name === 'school' || question.name === 'zipCode' || question.name === 'language'){
              return(
                <div key={index}>
                  <h1 >{question.content}</h1>
                  <input className='regularQuestion' onChange={(e)=>handle(e)} name={question.name} id={question.content} type={question.type} />
                </div>
              )
            }else{
            return(
              <div key={index}>
                <h1 >{question.content}</h1>
                <input className='regularQuestion' onChange={(e)=>handle(e)} name={question.name} id={question.content} type={question.type} />
                <p id='warning'>If The Question Doesn't Aply To You,<br/> Reply With <span id='good'>"None"</span></p>
              </div>

            )
            }

          })
        }
        <br /><br />
        <button>submit</button>
        <br /><br />
      </form>
      <div align='center' id="end">
        <h1>Thank you for completing the NYC Student Survey! Your opinions are important.<br/> Code for Life will release the app with all the results later in 2023. If you have any questions please call 917-405-3612 or email ctroy@codeforlife.us<br/>We will announce the release of the app later in 2023. Please visit our website <a href='https://codeforlife.us/'>https://codeforlife.us/</a></h1>
      </div>
    </div>
  )

}

export default App;